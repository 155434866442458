import { inject } from '@angular/core';
import { Injectable, } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Constants } from 'src/constants';
import { AppInsightService } from './app-insight';
import { AppInsights } from "applicationinsights-js";

@Injectable()

export class ErrorInterceptor implements HttpInterceptor {
  private appInsightService: AppInsightService;
  constructor() {
    this.appInsightService = inject(AppInsightService);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true
    });
    return next.handle(request).pipe(
      catchError(error => {
        const requestTimeMS = performance.now();
        const userInfomations: any = localStorage.getItem('loggedInUserInfo') && JSON.parse(localStorage.getItem('loggedInUserInfo'))
        const stackTrace: any = {
          name: userInfomations && userInfomations.userName,
          type: 'API-Response-Error',
          method: 'GET',
          url: request.url,
          duration: (performance.now() - requestTimeMS).toFixed(2) + ' ms',
          status: error.status,
          status_description: error.statusText || 'No status description provided',
          error_message: error.error ? JSON.stringify(error.error.message) : 'No error response was provided',
          email_address: userInfomations ? userInfomations.emailAddress : 'No user information provided',
          customer_number: userInfomations ? userInfomations.customerNumber : 'No user information provided'
        };

        // this.appInsightService.logError(JSON.stringify(stackTrace) )
        AppInsights.trackException(stackTrace.type, stackTrace.error_response, stackTrace);

        let message = ''
        if (error instanceof HttpErrorResponse) {
          if (error instanceof ErrorEvent) {
            console.error('Error Event');
          }
        } else {
          console.error('Other Errors');
        }
        return throwError(error);
      })
    );
  }

    handleError(error) {
    this.appInsightService.logError(error);
  }
}
