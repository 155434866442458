import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ErrorInterceptor} from './interceptor';
import { NgxSpinnerModule } from "ngx-spinner";
import { SharedService } from './shared.service';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        MatDialogModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule
    ],
    providers: [
        SharedService, { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ]
})
export class SharedModule { }
