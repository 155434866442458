import {ErrorHandler, Injectable, inject} from '@angular/core';
import {AppInsightService} from './app-insight';


@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  private appInsightService: AppInsightService;

  constructor() {
      this.appInsightService = inject(AppInsightService);
  }

  handleError(error) {
    const userInfomations: any = JSON.parse(localStorage.getItem('loggedInUserInfo'))
    const stackTrace: any = {
      name: userInfomations && userInfomations.userName,
      type: error.message,
      status_description: error.stack || 'No status description provided',
      error_message: error || 'No error response was provided',
      email_address: userInfomations ? userInfomations.emailAddress : 'No user information provided',
      customer_number: userInfomations ? userInfomations.customerNumber : 'No user information provided'
    };
  //  this.appInsightService.logError(error);
  }
}
