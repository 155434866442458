import { Injectable } from '@angular/core';
import { environment } from '../environments/environment'
import { Constants } from 'src/constants';
import { AppService } from './shared/app.service';

@Injectable()
export class AuthenticationService {

  constructor(private appService: AppService) { }

  userInfoAPI() {
    return this.appService.
      getAction(environment.apiUrl + Constants.endpointUrls.me);
  }

  authenticateAPI() {
    return this.appService.
      getAction(environment.apiUrl + Constants.endpointUrls.authenticate);
  }

}
