import { BrowserModule } from '@angular/platform-browser';
import { NgModule,ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { LocationStrategy, HashLocationStrategy} from '@angular/common';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppService } from './shared/app.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {ErrorInterceptor} from './shared/interceptor';
import { AuthGuard } from './shared/authGuard.service';
import {AppInsightService} from './shared/app-insight';
import {CustomErrorHandler} from './shared/custom-error-handler';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthenticationService } from './app-service';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule

  ],
  providers: [AppInsightService,AuthenticationService, AppService,
    AuthGuard, {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
       },],
  bootstrap: [AppComponent]
})
export class AppModule { }
