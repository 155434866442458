import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Injectable()
export class AppService {
  private maxTimeout = 80000;
  constructor(private http: HttpClient, private spinner: NgxSpinnerService,  private router: Router,) {
  }

  // Navigate into another page
  navigateTo = (path: string): void => {
    this.router.navigate([path]);
  }

  // Clear local Storage
  clearData = (): void => {
    localStorage.clear();
  }

  // common get method
  getAction(path: string) {
    this.showLoader();
    return this.http.get(path)
      .pipe(timeout(this.maxTimeout), map(res => {
        this.hideLoader();
        return this.extractData(res);
      }),
        catchError(err => {
          this.hideLoader();
          return throwError(err);
        }));
  }

  // common post method
  postAction(path: string, body?: any) {
    this.spinner.show();
    return this.http.post(path, body)
      .pipe(timeout(this.maxTimeout), map(res => {
        this.hideLoader();
        return this.extractData(res);
      }),
        catchError(err => {
          this.hideLoader();
          return throwError(err);
        }));
  }

  errorHandl(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }

  showLoader() {
    this.spinner.show();
  }

  hideLoader() {
    this.spinner.hide();
  }

  private extractData(res: any) {
    if (res && res.status === 200) {
      return res.json() || {};
    } else {
      return res || {};
    }
  }
}
