// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 //apiUrl: 'https://nsapi-dev.concord.net/api/', // Dev Env
 // apiUrl: 'https://nsapi-nse.concord.net/api/', // Stage Env
  //apiUrl: 'https://nsapi-qa.concord.net/api/', // QA Env
 // apiUrl: 'https://nextstep.concord.net/api/', //PROD Env
  apiUrl: '#{ApiUrl}#',
   appInsights: {
 // instrumentationKey:'92ef91cd-3acd-4589-a7c5-d21ee395720e' /*Dev App Insights Key*/
 // instrumentationKey:'c6110e7d-3a8b-43d8-a698-5db4a93f1d5a' /*QA App Insights Key*/
 // instrumentationKey: 'c240a666-c258-456c-b967-b96e6f8e7289' /*Stage App Insights Key*/
 // instrumentationKey:"b15c64c7-5f74-4ee4-80aa-acf5b0cd2e8c" /*Prod App Insights Key*/
  instrumentationKey:"#{ApplicationInsights--InstrumentationKey}#"

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
