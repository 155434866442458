import { Component } from '@angular/core';
import { AuthenticationService } from './app-service';
import { AppInsightService } from './shared/app-insight';
import { environment } from '../environments/environment'
import * as _ from 'lodash';
import { Constants } from 'src/constants';
import { AlertDialogComponent, AlertTypes } from './shared/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'NEXTSTEP';

  constructor(
    private appInsightService: AppInsightService,
    private authService: AuthenticationService,
    private dialog: MatDialog
  ) { }

  redirectURL: any;
  isSupportEnabled: any
  ngOnInit(): void {
    this.isUserAuthenticated();
  }

  // check the user is authenticated or not if user is authenticated, call the me API otherwise go to login screen
  isUserAuthenticated = (): void => {
    this.authService.authenticateAPI().subscribe(response => {
      if (response && response.message && response.message == 'User is authenticated') {
          this.getUserInfo();
      } else if (response && response.message && response.message == 'User is not authenticated') {
          window.location.href = environment.apiUrl + Constants.endpointUrls.singlesignon;
      }
    }, error => {
        if (error && error.status === 404) {
          this.getUserInfo();
        } else {
            window.location.href = environment.apiUrl + Constants.endpointUrls.singlesignon;
        }
    });
  }

  // Get user details using '/authentication/me' API
  getUserInfo = (): void => {
    this.authService.userInfoAPI().subscribe(response => {
      if (response) {
        this.setLoginData(response);
      }
    }, error => {
      if (error.status === 403) {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          panelClass: 'alert-dialog',
          disableClose: true,
          data: {
            msg: Constants.messages[403],
            type: AlertTypes.ALERTCONFIRM
          }
        });
        dialogRef.keydownEvents().subscribe(e => {
          if (e.keyCode === 27) {
            dialogRef.close();
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          window.location.href = environment.apiUrl + Constants.endpointUrls.singlesignon;
        });
      } else {
        window.location.href = environment.apiUrl + Constants.endpointUrls.singlesignon;
      }
    });
  }

  // stored on the localstorage for the future purpose  /authentication/me API and stored on the localstorage.loggedInUserInfo
  setLoginData = (data): void => {
    localStorage.setItem('customerNumber', data.accountNumber);
    localStorage.setItem('TOKEN', data.token);
    localStorage.setItem('loggedInUserInfo', JSON.stringify(data))
    if (data && data.roleNames && data.roleNames.length) {
      _.forEach(data.roleNames, (item, i) => {
        if (item === 'Platform Support') {
          localStorage.setItem('supportAccess', 'true');
          this.isSupportEnabled = true
        }
      });
    }
    if (data.type === 1) {
      this.isSupportEnabled ? this.redirectURL = '/support/' : this.redirectURL = '/admin/'
    } else if (data.type === 2) {
      this.isSupportEnabled ? this.redirectURL = '/support/' : this.redirectURL = '/app/'
    }
    window.location.assign(this.redirectURL);
  }
}
