import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {SharedService} from '../shared.service'
import { Router } from '@angular/router';

export enum AlertTypes {
  ALERT = 'alert',
  SUCCESS = 'success',
  CONFIRM = 'confirm',
  INFO = 'info',
  WARN = 'warn',
  DOWN = 'down',
  ALERTCONFIRM  = 'alertconfirm',
  FIELDALERTCONFIRM  = 'fieldalertconfirm',
  PROCESSALERTCONFIRM  = 'processalertconfirm',
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  checkNow = true;
  timer: any;
  public types: typeof AlertTypes = AlertTypes;

  constructor(
    public sharedService: SharedService,
    private router: Router,
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }



  ngOnInit() {
    console.log(this.data.type);
   
  }


 
  // On Click Close
  onClickClose() {
    this.checkNow = false;
    this.dialogRef.close();
    sessionStorage.removeItem('popUploaded');
  }

  // On Click Okay
  onClickOk() {
    sessionStorage.removeItem('popUploaded');
    this.dialogRef.close(true);

  }

}
