<div class="alert-wrapper">
    
    


    <div >
        <div class="text-center">
            <div>
                <img src="/assets/images/alert.svg" class="alert_icon">
            </div>
            <div class="mt-3 mb-3 cursor-default"> {{data.msg}}</div>
        </div>
        <div class="text-center" id="alert">
            <button mat-flat-button color="accent" (click)="onClickClose()">Retry</button>
        </div>
    </div>

</div>