export const Constants = {
    pattern: {
      email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      mobile: /^[1-9][0-9]*$/,
      onlyNumber: /^[1-9][0-9]*$/,
      name: /^[A-Za-z][a-zA-Z ]*$/,
      password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[$@$!%*#?&0-9])[0-9A-Za-z\d$@$!%*#?&]{8,}$/,
    },
    messages: {
      400: 'Unable to process this request due to input is not valid, Please contact support.',
      401: 'Unauthorized',
      403: 'An error occurred',
      404: 'The specified api url does not exist, Please contact support.',
      409: 'Unable to process this request due to a conflict, Please contact support.',
      500: 'The execution of the service failed, Please contact support.',
      502: 'Bad Gateway - The execution of the service failed, Please contact support.',
      default: 'Service Unavailable, Please contact support.'

    },
    endpointUrls: {
      me: 'authentication/me',
      authenticate: 'authentication/status',
      singlesignon: 'authentication/SingleSignOn',
    }
  };
